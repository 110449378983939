import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
Swiper.use([Navigation, Pagination]);

bcl.c.mybCurrentBooking = {
  props: {
    component: "c-myb-current-bookings",
    detailsButton: ".js-show-details",
    buttonMap: ".js-open-map",
    buttonMapTop: ".js-open-map-modal",
    showMoreButton: ".js-show-more",
    detailsBlock: ".js-details-block",
    extraInfoBlock: ".js-extraInfo-block",
    bookingsBlock: ".js-bookings-block",
    firstClick: true,
    nameLatAttr: "data-location-lat",
    nameLngAttr: "data-location-lng",
    containerMap: null,
    bookingsData: null,
    checkInTime: null,
    checkOutTime: null,
    serviceList: null,
    emptyText: ".js-empty-current-booking-text",
  },

  data: {},

  preInit: function () {
    bcl.u.docReady(bcl.c.mybCurrentBooking.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.getElementsByClassName(bcl.c.mybCurrentBooking.props.component);

    if (!$component || !$component[0]) {
      return;
    }
    bcl.c.mybCurrentBooking.props.endpointPostData = $component[0].dataset.urlPostData;
    bcl.c.mybCurrentBooking.setInputs($component[0]);
  },

  addListeners: function (element) {
    const items = element.querySelectorAll(".js-booking-item");
    items.forEach((item) => {
      let fetchCompleted = false;
      const detailsButton = item.querySelector(bcl.c.mybCurrentBooking.props.detailsButton);

      if (detailsButton) {
        detailsButton.addEventListener("click", function () {
          if (item.dataset.generatePdf == undefined) {
            item.classList.toggle("mod--open");
            const bookingsBlock = document.querySelector(bcl.c.mybCurrentBooking.props.bookingsBlock);

            if (item.classList.contains("mod--open")) {
              detailsButton.innerHTML = `${bookingsBlock.dataset.hidedetails}`;
            } else {
              detailsButton.innerHTML = `${bookingsBlock.dataset.showdetails}`;
            }
            const detailsBlock = item.querySelector(bcl.c.mybCurrentBooking.props.detailsBlock);
            detailsBlock.classList.toggle("mod--open");
          }

          if (!fetchCompleted) {
            const urlRoom = item.dataset.roompath;
            // DEV PATH
            const selectorUrlRoom = urlRoom.replace(/\/$/, "") + ".myb-room.html";

            // LOCAL TEST
            // const indexRoom = urlRoom.lastIndexOf(".html");
            // let selectorUrlRoom = urlRoom;
            // if (indexRoom !== -1) {
            //   selectorUrlRoom = urlRoom.slice(0, indexRoom) + ".myb-room" + urlRoom.slice(indexRoom);
            // }

            fetch(selectorUrlRoom)
              .then((response) => response.text())
              .then((data) => {
                const parser = new DOMParser();
                const roomDoc = parser.parseFromString(data, "text/html");

                const iconList = roomDoc.querySelector(".js-list-items");
                const carousel = roomDoc.querySelector(".js-load-carousel");
                let swiper;
                let swiperWrapper;
                if (carousel) {
                  swiper = carousel.querySelector(".swiper");
                  swiperWrapper = carousel.querySelector(".swiper-wrapper");
                }
                const carouselBlock = item.querySelector(".js-carousel-block");
                const servicesBlock = item.querySelector(".js-services-block");

                const checkInHourElement = roomDoc.querySelector(".js-check-in");
                const checkOutHourElement = roomDoc.querySelector(".js-check-out");

                if (checkInHourElement && checkOutHourElement) {
                  const checkInHour = checkInHourElement.textContent.trim();
                  const formattedCheckInHour = bcl.c.mybCurrentBooking.formatTime(checkInHour);
                  if (formattedCheckInHour) {
                    checkInHourElement.textContent = formattedCheckInHour;
                  }

                  const checkOutHour = checkOutHourElement.textContent.trim();
                  const formattedCheckOutHour = bcl.c.mybCurrentBooking.formatTime(checkOutHour);
                  if (formattedCheckOutHour) {
                    checkOutHourElement.textContent = formattedCheckOutHour;
                  }

                  const checkInBlock = item.querySelector(".js-checking-time");
                  const checkOutBlock = item.querySelector(".js-checkout-time");

                  if (checkInBlock && checkOutBlock) {
                    checkInBlock.appendChild(checkInHourElement);
                    checkOutBlock.appendChild(checkOutHourElement);
                    bcl.c.mybCurrentBooking.props.checkInTime = checkInHourElement;
                    bcl.c.mybCurrentBooking.props.checkOutTime = checkOutHourElement;
                  }
                }
                if (servicesBlock && iconList) {
                  servicesBlock.appendChild(iconList);
                  bcl.c.mybCurrentBooking.props.serviceList = iconList.children;
                }

                if (item.dataset.generatePdf != undefined) {
                  bcl.c.mybCurrentBooking.generatePDFContent(element, item);
                  item.removeAttribute("data-generate-pdf");
                }

                if (carouselBlock && swiper) {
                  carouselBlock.appendChild(swiper);
                }

                const carouselUrl = item.querySelector("[data-items-load]").getAttribute("data-items-load");
                fetch(carouselUrl)
                  .then((res) => res.text())
                  .then((content) => {
                    const parser = new DOMParser();
                    const response = parser.parseFromString(content, "text/html");
                    const items = response.querySelectorAll(".swiper-slide");
                    if (items && swiperWrapper) {
                      items.forEach((item) => {
                        swiperWrapper.append(item);
                      });
                      new Swiper(swiper, {
                        slidesPerView: 1,
                        loop: true,
                        pagination: {
                          el: ".swiper-pagination",
                          clickable: true,
                        },
                        navigation: {
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        },
                      });
                    }
                  });
              });
            fetchCompleted = true;
          } else {
            if (item.dataset.generatePdf != undefined) {
              bcl.c.mybCurrentBooking.generatePDFContent(element, item);
              item.removeAttribute("data-generate-pdf");
            }
          }
        });
      }

      const showMoreButton = item.querySelector(bcl.c.mybCurrentBooking.props.showMoreButton);
      if (showMoreButton) {
        showMoreButton.addEventListener("click", function () {
          const extraInfoBlock = item.querySelector(bcl.c.mybCurrentBooking.props.extraInfoBlock);
          const detailsBlock = item.querySelector(bcl.c.mybCurrentBooking.props.detailsBlock);
          extraInfoBlock.classList.toggle("mod--open");
          detailsBlock.classList.toggle("mod--open-extra");
        });
      }

      const mapButton = item.querySelector(bcl.c.mybCurrentBooking.props.buttonMap);
      const mapButtonTop = item.querySelector(bcl.c.mybCurrentBooking.props.buttonMapTop);
      let fetchMap = false;

      if (mapButton && mapButtonTop) {
        const initMapModal = function () {
          const urlMap = item.dataset.mappath;
          if (urlMap && !fetchMap) {
            fetch(urlMap)
              .then((response) => response.text())
              .then((data) => {
                const parser = new DOMParser();
                const mapDoc = parser.parseFromString(data, "text/html");
                const mapModal = mapDoc.querySelector(".js-c-hotel-map-card__modal");
                const pagePosition = window.scrollY;
                mapModal.classList.add("mod--open");
                document.body.style.top = "-" + pagePosition + "px";
                document.body.classList.add("tingle-enabled");
                const mapContent = mapModal.querySelector(".js-map-content");
                const closeMapButton = mapModal.querySelector(".js-c-hotel-map-card__modal-close");
                const expandMapButton = mapModal.querySelector(".js-c-hotel-map-card__modal-expand");
                item.appendChild(mapModal);
                bcl.c.mybCurrentBooking.initMap(mapContent);
                bcl.c.mybCurrentBooking.modalHandler(mapModal, closeMapButton, pagePosition, expandMapButton);
                fetchMap = true;
              });
          } else if (fetchMap) {
            const mapModal = item.querySelector(".js-c-hotel-map-card__modal");
            const closeMapButton = mapModal.querySelector(".js-c-hotel-map-card__modal-close");
            const expandMapButton = mapModal.querySelector(".js-c-hotel-map-card__modal-expand");
            mapModal.classList.toggle("mod--open");
            const pagePosition = window.scrollY;
            document.body.style.top = "-" + pagePosition + "px";
            document.body.classList.add("tingle-enabled");
            bcl.c.mybCurrentBooking.modalHandler(mapModal, closeMapButton, pagePosition, expandMapButton);
          }
        };

        mapButton.addEventListener("click", function () {
          if (!fetchMap) {
            bcl.map.ready(initMapModal);
            bcl.map.init();
          } else {
            initMapModal();
          }
        });
        mapButtonTop.addEventListener("click", function () {
          if (!fetchMap) {
            bcl.map.ready(initMapModal);
            bcl.map.init();
          } else {
            initMapModal();
          }
        });
      }

      const loader = item.querySelector(".js-loader");
      const downloadButtons = item.querySelectorAll(".js-download-button");
      if (downloadButtons) {
        downloadButtons.forEach((downloadButton) => {
          downloadButton.addEventListener("click", function () {
            item.dataset.generatePdf = true;
            item.dataset.funcionality = "download";
            loader.style.display = "flex";
            detailsButton.click();
          });
        });
      }

      const printButtons = item.querySelectorAll(".js-print-button");
      printButtons.forEach((printButton) => {
        printButton.addEventListener("click", function () {
          item.dataset.generatePdf = true;
          item.dataset.funcionality = "print";
          loader.style.display = "flex";
          detailsButton.click();
        });
      });

      const shareButtons = item.querySelectorAll(".js-share-button");
      shareButtons.forEach((shareButton) => {
        shareButton.addEventListener("click", function () {
          item.dataset.generatePdf = true;
          item.dataset.funcionality = "share";
          loader.style.display = "flex";
          detailsButton.click();
        });
      });
    });
  },

  getApiData: function (element) {
    bcl.ajax.postRequest({
      data: bcl.c.mybCurrentBooking.props.info,
      url: bcl.c.mybCurrentBooking.props.endpointPostData,
      callback: (e) => {
        bcl.c.mybCurrentBooking.setApiData(e.responseText);
        bcl.c.mybCurrentBooking.addListeners(element);
      },
    });
  },

  createGoogleCalendarLink: function (checkIn, checkOut, title, location) {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getUTCFullYear().toString().padStart(4, "0");
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = date.getUTCDate().toString().padStart(2, "0");
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
    };

    const formattedCheckIn = formatDate(checkIn);
    const formattedCheckOut = formatDate(checkOut);

    const googleCalendarUrl = "http://www.google.com/calendar/event?action=TEMPLATE&dates=" + formattedCheckIn + "/" + formattedCheckOut + "&text=" + encodeURIComponent(title) + "&location=" + encodeURIComponent(location);

    return googleCalendarUrl;
  },

  setApiData: function (responseText) {
    const data = JSON.parse(responseText);
    bcl.c.mybCurrentBooking.props.bookingsData = data;
    const bookingsData = data;

    const bookingsBlock = document.querySelector(bcl.c.mybCurrentBooking.props.bookingsBlock);
    if (bookingsData.length > 0) {
      bookingsData.forEach((booking) => {
        const checkIn = booking.checkIn;
        const checkOut = booking.checkOut;
        const checkInDate = new Date(checkIn);
        const checkOutDate = new Date(checkOut);

        booking.amount = bcl.c.mybCurrentBooking.formatAmountBasedOnLang(booking.amount);

        const differenceInMilliseconds = checkOutDate - checkInDate;
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const differenceInDays = differenceInMilliseconds / millisecondsPerDay;
        const nights = Math.round(differenceInDays);
        const options = { weekday: "short", day: "numeric", month: "short" };
        const formatter = new Intl.DateTimeFormat("es-ES", options);
        const formattedCheckIn = formatter.format(checkInDate);
        const formattedCheckOut = formatter.format(checkOutDate);
        const year = checkInDate.getFullYear();
        const formattedDateRange = `${formattedCheckIn} - ${formattedCheckOut}, ${year} (${nights} noches)`;

        const adults = `${booking.adults} ${booking.adults > 1 ? bookingsBlock.dataset.adults : bookingsBlock.dataset.adult}`;
        let babies = "";
        let childrens = "";
        if (booking.children) {
          childrens = `${booking.children} ${booking.children > 1 ? bookingsBlock.dataset.childrens : bookingsBlock.dataset.children}`;
        }
        if (booking.babyOccupancy) {
          babies = `${booking.babyOccupancy} ${booking.babyOccupancy > 1 ? bookingsBlock.dataset.babies : bookingsBlock.dataset.baby}`;
        }

        const status = booking.status == "FIR" ? bookingsBlock.dataset.completed : bookingsBlock.dataset.cancelled;

        const bookingItem = document.createElement("div");
        bookingItem.classList.add("c-myb-current-bookings__item", "js-booking-item");
        bookingItem.setAttribute("data-roompath", booking.roomPath);
        bookingItem.setAttribute("data-hotelpath", booking.hotelPath);
        bookingItem.setAttribute("data-mappath", booking.mapDetail);
        bookingItem.setAttribute("data-map-id", "myb-map-modal");
        bookingItem.setAttribute("data-locator", booking.reservationSynxisCode);

        if (booking.hotelPath != "") {
          bookingItem.innerHTML = `
            <div class="c-myb-current-bookings__item-header">
                    <div class="c-myb-current-bookings__item-header__container">
                        <div class="c-myb-current-bookings__item-header__locator">
                            ${bookingsBlock.dataset.locator} <span class="c-myb-current-bookings__item-header__locator-code">${booking.reservationSynxisCode}</span> 
                        </div>
                        <span class="c-myb-current-bookings__item-header__ribbon">${status}</span>
                    </div>
                    
                    <div class="c-myb-current-bookings__item-header__icons">
                        <div class="c-myb-current-bookings__item-header__icons-item js-print-button">
                            <span class="c-myb-current-bookings__item-header__icons-icon mod--print"></span>
                            <div class="c-myb-current-bookings__item-header__icons-label">${bookingsBlock.dataset.print}</div>
                        </div>
                        <div class="c-myb-current-bookings__item-header__icons-item js-download-button">
                            <span class="c-myb-current-bookings__item-header__icons-icon mod--download"></span>
                            <div class="c-myb-current-bookings__item-header__icons-label">${bookingsBlock.dataset.download}</div>
                        </div>
                        <div class="c-myb-current-bookings__item-header__icons-item js-share-button">
                            <span class="c-myb-current-bookings__item-header__icons-icon mod--share"></span>
                            <div class="c-myb-current-bookings__item-header__icons-label">${bookingsBlock.dataset.share}</div>
                        </div>
                        <span class="c-myb-current-bookings__loader js-loader"></span>
                    </div>
                </div>
                <div class="c-myb-current-bookings__item-locator">
                    ${bookingsBlock.dataset.locator}: <span class="c-myb-current-bookings__item-locator__code">${booking.reservationSynxisCode}</span> 
                </div>
                <span class="c-myb-current-bookings__item-tag">${bookingsBlock.dataset.hoteltype}</span>
                <div class="c-myb-current-bookings__item-hotel">
                    <div class="c-myb-current-bookings__item-hotel__top">
                        <img class="c-myb-current-bookings__item-hotel__img" src="${booking.hotelImage}"/>
                        <div class="c-myb-current-bookings__item-hotel__info">
                            <span class="c-myb-current-bookings__item-hotel__info-tag">${bookingsBlock.dataset.hoteltype}</span>
                            <div class="c-myb-current-bookings__item-hotel__info-name">${booking.hotelName}</div>
                            <div class="c-myb-current-bookings__item-hotel__info-location js-open-map-modal ${booking.mapDetail ? "" : "mod--disabled"}">
                              <span class="c-myb-current-bookings__item-hotel__info-location__icon"></span>
                              ${booking.hotelLocation}
                            </div>
                        </div>
                    </div>
                    <div class="c-myb-current-bookings__item-hotel__data">
                        <div class="c-myb-current-bookings__item-hotel__data-room">
                            <span class="c-myb-current-bookings__item-hotel__data-room__title">${bookingsBlock.dataset.room}</span>
                            <div class="c-myb-current-bookings__item-hotel__data-room__name">${booking.roomName}</div>
                        </div>
                        <div class="c-myb-current-bookings__item-hotel__data-price">
                            <span class="c-myb-current-bookings__item-hotel__data-price__title">${bookingsBlock.dataset.amount}</span>
                            <div class="c-myb-current-bookings__item-hotel__data-price__num">${booking.amount} ${booking.currency}</div>
                            <span class="c-myb-current-bookings__item-hotel__data-price__tag">${bookingsBlock.dataset.taxes}</span>
                        </div>
                    </div>
                </div>
                <div class="c-myb-current-bookings__item-buttons">
                    <div class="c-myb-current-bookings__item-buttons__show mod--underline-small js-show-details">${bookingsBlock.dataset.showdetails}</div>
                    <div class="c-myb-current-bookings__item-buttons__container">
                        <button class="c-cta mod--cta-secondary">
                          <a onclick="bcl.c.cta.openMybBookings()" href="#" target="_blank" aria-label="${bookingsBlock.dataset.reservationsarialabel}">
                            ${bookingsBlock.dataset.managebookings}
                          <span class="c-cta__icon mod--primary-arrow"></span>
                          </a>
                        </button>
                        <button class="c-cta mod--cta-primary">
                          <a href="${bookingsBlock.dataset.checkinlink}" target="_blank" aria-label="${bookingsBlock.dataset.checkinarialabel}">
                            ${bookingsBlock.dataset.checkinonline}
                          <span class="c-cta__icon mod--white-arrow"></span>
                          </a>
                        </button>
                    </div>
                </div>
                <div class="c-myb-current-bookings__item-details js-details-block">
                    <div class="c-myb-current-bookings__item-details__carousel js-carousel-block">
                    </div>
                    <div class="c-myb-current-bookings__item-details__info">
                        <div class="c-myb-current-bookings__item-details__info-title">${bookingsBlock.dataset.bookingdetails}</div>
                        <div class="c-myb-current-bookings__item-details__info-location">
                        <div class="c-myb-current-bookings__item-details__info-location__container">
                          <span class="c-myb-current-bookings__item-details__info-location__icon"></span>
                          <div ${booking.mapDetail ? "" : "style='text-decoration: none;'"} class="c-myb-current-bookings__item-details__info-location__text">${booking.hotelLocation}</div>
                        </div>
                            <div class="c-myb-current-bookings__item-details__info-location__link js-open-map mod--underline-small ${booking.mapDetail ? "" : "mod--hidden"}">${bookingsBlock.dataset.howtoget}</div>
                        </div>
                        <div class="c-myb-current-bookings__item-details__info-date">
                            <div class="c-myb-current-bookings__item-details__info-date__container">
                              <span class="c-myb-current-bookings__item-details__info-date__icon"></span>
                              <div class="c-myb-current-bookings__item-details__info-date__text">${formattedDateRange}</div>
                            </div>
                            <div class="c-myb-current-bookings__item-details__info-date__time">${bookingsBlock.dataset.checkin} <span class="js-checking-time"></span></div>
                            <div class="c-myb-current-bookings__item-details__info-date__time">${bookingsBlock.dataset.checkout} <span class="js-checkout-time"></span></div>
                            <div class="c-myb-current-bookings__item-details__info-date__link mod--underline-small">
                              <a href="" class="js-google-calendar">${bookingsBlock.dataset.calendar}</a>
                            </div>
                        </div>
                        <div class="c-myb-current-bookings__item-details__info-people">
                            <span class="c-myb-current-bookings__item-details__info-people__icon"></span>
                            ${adults}
                        </div>
                        <div class="c-myb-current-bookings__item-details__info-people ${booking.children ? "" : "mod--hidden"}">
                            <span class="c-myb-current-bookings__item-details__info-people__icon"></span>
                            ${childrens}
                        </div>
                        <div class="c-myb-current-bookings__item-details__info-people  ${booking.babyOccupancy ? "" : "mod--hidden"}">
                            <span class="c-myb-current-bookings__item-details__info-people__icon"></span>
                            ${babies}
                        </div>
                        <div class="c-myb-current-bookings__item-details__info-showMore js-show-more">${bookingsBlock.dataset.seemore}</div>
                        <div class="c-myb-current-bookings__item-details__extraInfo js-extraInfo-block">
                            <div class="c-myb-current-bookings__item-details__extraInfo-title">${bookingsBlock.dataset.additionalinfo}</div>
                            <div class="c-myb-current-bookings__item-details__extraInfo-services js-services-block">
                                
                            </div>
                            <div class="c-myb-current-bookings__item-details__extraInfo-payments">${bookingsBlock.dataset.onpayments}</div>
                            <div class="c-myb-current-bookings__item-details__extraInfo-payments__title">
                                  ${bookingsBlock.dataset.nonrefundable}
                            </div>
                            <div class="c-myb-current-bookings__item-details__extraInfo-payments__text">
                                ${bookingsBlock.dataset.selectedrate}. ${bookingsBlock.dataset.cancellationpayment}: ${booking.amount} ${booking.currency}.
                            </div>
                            <div class="c-myb-current-bookings__item-details__extraInfo-payments__title">
                                  ${bookingsBlock.dataset.alreadypaid}
                            </div>
                            <div class="c-myb-current-bookings__item-details__extraInfo-payments__text">
                                ${bookingsBlock.dataset.alreadypaid}: ${booking.amount} ${booking.currency}.
                            </div>
                        </div>
                    </div>
                </div>
            `;
          bookingsBlock.appendChild(bookingItem);
          const title = bookingsBlock.dataset.roombook;
          const location = booking.hotelLocation;

          const googleCalendarLink = bcl.c.mybCurrentBooking.createGoogleCalendarLink(checkIn, checkOut, title, location);
          const linkGoogle = bookingItem.querySelector(".js-google-calendar");
          linkGoogle.setAttribute("href", googleCalendarLink);
        }
      });
    } else {
      const emptyText = document.querySelector(bcl.c.mybCurrentBooking.props.emptyText);
      emptyText.style.display = "block";
    }
  },

  setInputs: function (element) {
    bcl.c.mybCurrentBooking.getApiData(element);
  },

  formatAmountBasedOnLang: function (value) {
    const lang = document.documentElement.lang;
    value = parseFloat(value);
    if (lang.includes("en")) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      const parts = value.toFixed(2).split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    }
  },

  initMap: function (component) {
    const lat = component.getAttribute(bcl.c.mybCurrentBooking.props.nameLatAttr),
      lng = component.getAttribute(bcl.c.mybCurrentBooking.props.nameLngAttr);
    let zoom = parseInt(component.dataset.zoom);

    const modalMap = component.querySelector(".js-c-hotel-map-card__modal__map-wrapper");

    if (!zoom) {
      zoom = 12;
    }

    const point = new google.maps.LatLng(lat, lng);
    const centerMap = point;

    const map = new google.maps.Map(modalMap, {
      center: centerMap, // -0.1
      scrollwheel: true,
      zoom: zoom,
      fullscreenControl: false,
      minZoom: false,
      maxZoom: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      styles: bcl.map.props.mapStyles.defaultStyle,
    });

    new google.maps.Marker({
      map: map,
      position: point,
      icon: new google.maps.MarkerImage("/content/dam/barcelo/commons/icons/statics/poi_barcelo__black-B.svg", null, null, null, new google.maps.Size(48, 48)),
    });

    google.maps.event.addListenerOnce(map, "idle", function () {
      google.maps.event.trigger(map, "resize");
      map.setCenter(point);
    });
  },

  modalHandler: function ($modal, $closeModal, pagePosition, $expandButton) {
    if ($expandButton) {
      $expandButton.addEventListener("click", () => {
        $modal.classList.toggle("mod--expand");
      });
    }
    $modal.addEventListener("click", (e) => {
      if (e.target.isEqualNode($modal)) {
        $modal.classList.remove("mod--open");
        document.body.classList.remove("tingle-enabled");
        window.scrollTo(0, pagePosition);
        document.body.style.top = null;
      }
    });

    $closeModal.addEventListener("click", () => {
      $modal.classList.remove("mod--open");
      document.body.classList.remove("tingle-enabled");
      window.scrollTo(0, pagePosition);
      document.body.style.top = null;
    });
  },
  formatTime(timeStr) {
    if (timeStr.length === 4) {
      const hours = timeStr.slice(0, 2);
      const minutes = timeStr.slice(2);
      return `${hours}:${minutes}`;
    } else {
      console.error("Formato de tiempo no válido");
      return null;
    }
  },
  generatePDFContent: function (element, item) {
    const bookingsData = bcl.c.mybCurrentBooking.props.bookingsData;
    const bookingsBlock = element.querySelector(bcl.c.mybCurrentBooking.props.bookingsBlock);
    const locator = item.dataset.locator;

    if (bookingsData) {
      bookingsData.forEach((booking) => {
        if (locator == booking.reservationSynxisCode) {
          const checkIn = booking.checkIn;
          const checkOut = booking.checkOut;
          const checkInDate = new Date(checkIn);
          const checkOutDate = new Date(checkOut);

          const differenceInMilliseconds = checkOutDate - checkInDate;
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          const differenceInDays = differenceInMilliseconds / millisecondsPerDay;
          const nights = Math.round(differenceInDays);
          const options = { weekday: "short", day: "numeric", month: "short" };
          const formatter = new Intl.DateTimeFormat("es-ES", options);
          const formattedCheckIn = formatter.format(checkInDate);
          const formattedCheckOut = formatter.format(checkOutDate);
          const year = checkInDate.getFullYear();
          const formattedDateRange = `${formattedCheckIn} - ${formattedCheckOut}, ${year} (${nights} noches)`;

          const adults = `${booking.adults} ${booking.adults > 1 ? bookingsBlock.dataset.adults : bookingsBlock.dataset.adult}`;
          let babies = "";
          let childrens = "";
          if (booking.children) {
            childrens = `${booking.children} ${booking.children > 1 ? bookingsBlock.dataset.childrens : bookingsBlock.dataset.children}`;
          }
          if (booking.babyOccupancy) {
            babies = `${booking.babyOccupancy} ${booking.babyOccupancy > 1 ? bookingsBlock.dataset.babies : bookingsBlock.dataset.baby}`;
          }

          const status = booking.status == "FIR" ? bookingsBlock.dataset.confirmed : bookingsBlock.dataset.cancelled;

          const checkInTime = bcl.c.mybCurrentBooking.props.checkInTime;
          const checkOutTime = bcl.c.mybCurrentBooking.props.checkOutTime;
          const serviceList = bcl.c.mybCurrentBooking.props.serviceList;

          booking.amount = bcl.c.mybCurrentBooking.formatAmountBasedOnLang(booking.amount);

          let pdfContent = "<div style='border: 1px solid #D7D9DA; border-radius: 16px; padding: 32px 48px; margin: 32px;'>";

          pdfContent += `<div style="display: flex; flex-direction: row; align-items: center; gap: 16px; margin-bottom: 24px;">
              <div style="display: flex; flex-direction: column; font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.015em; color: #606669; text-transform: uppercase;">
                  ${bookingsBlock.dataset.locator} <span>${booking.reservationSynxisCode}</span> 
              </div>
              <span style="font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.004em; background-color: #EAEEF1; border-radius: 8px; color: #384044; padding: 4px 8px;">${status}</span>
            </div>

            <div style="display: flex; flex-direction: row; gap: 24px; border-bottom: 1px solid #D7D9DA;">
              <div style="display: flex; flex-direction: row; gap: 16px; width: 50%; margin-bottom: 24px;">
                <img style="width: 48px; height: 48px; border: 1px solid grey; border-radius: 8px" src="${location.origin + booking.hotelImage}"/>
                <div style="display: flex; flex-direction: column;">
                    <span style="display: inline-block; font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.015em; color: #606669; text-transform: uppercase;">${bookingsBlock.dataset.hoteltype}</span>
                    <div style="font-size: 20px; font-weight: 600; line-height: 30px; letter-spacing: 0.0015em; color: #384044;">${booking.hotelName}</div>
                    <div style="display: flex; font-size: 16px; font-weight: 300; line-height: 24px; color: #384044;">
                      <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/location.svg"}" style="width: 22px; height: 16px; display: block; margin-right: 4px; padding: 0 5px; object-fit: contain;"/>
                      ${booking.hotelLocation}
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction: row; width: 50%; margin-bottom: 24px;">
                <div style="display: flex; flex-direction: column; flex-grow: 1;">
                  <span style="font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.015em; text-transform: uppercase; color: #606669;">${bookingsBlock.dataset.room}</span>
                  <div style="font-size: 20px; font-weight: 600; line-height: 30px; letter-spacing: 0.0015em; color: #384044;">${booking.roomName}</div>
                </div>
                <div style="display: flex; width: 44%; flex-direction: column; align-items: flex-end;">
                  <span style="font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.015em; text-transform: uppercase; color: #606669;">${bookingsBlock.dataset.amount}</span>
                  <div style="font-size: 20px; font-weight: 600; line-height: 30px; letter-spacing: 0.0015em; color: #384044;">${booking.amount} ${booking.currency}</div>
                  <span style="font-size: 12px; font-weight: 300; line-height: 16px; letter-spacing: 0.004em; color: #606669;">${bookingsBlock.dataset.taxes}</span>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 24px; padding-top: 24px;">
              <div style="font-size: 16px; font-weight: 300; line-height: 24px; letter-spacing: 0.005em; color: #384044;">
                <div style="font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.015em; color: #606669; text-transform: uppercase; margin-bottom: 24px; break-inside: avoid;">${bookingsBlock.dataset.bookingdetails}</div>
                <div style="margin-bottom: 24px; break-inside: avoid;">
                  <div style="display: flex; gap: 12px; margin-bottom: 4px;">
                    <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/calendar.svg"}" style="width: 24px; display: inline-block;"/>
                    <div style="text-transform: capitalize;">${formattedDateRange}</div>
                  </div>
                  <div style="font-size: 14px; font-weight: 300; line-height: 20px; letter-spacing: 0.0025em; color: #606669; margin-left: 36px; margin-bottom: 4px;">${bookingsBlock.dataset.checkin} <span>${checkInTime == null ? "" : checkInTime}</span></div>
                  <div style="font-size: 14px; font-weight: 300; line-height: 20px; letter-spacing: 0.0025em; color: #606669; margin-left: 36px; margin-bottom: 4px;">${bookingsBlock.dataset.checkout} <span>${checkOutTime == null ? "" : checkOutTime}</span></div>
                </div>
                <div style="display: flex; gap: 12px; margin-bottom: 24px; break-inside: avoid;">
                    <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/people.svg"}" style="width: 24px; display: inline-block;"/>
                    ${adults}
                </div>`;

          if (booking.children) {
            pdfContent += `<div style="display: flex; gap: 12px; margin-bottom: 24px; break-inside: avoid;">
                    <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/people.svg"}" style="width: 24px; display: inline-block;"/>
                    ${childrens}
                  </div>`;
          }

          if (booking.babyOccupancy) {
            pdfContent += `<div style="display: flex; gap: 12px; margin-bottom: 24px; break-inside: avoid;">
                    <img src="${location.origin + "/etc.clientlibs/barcelo/clientlibs/clientlib-mybarcelo/resources/images/booking/people.svg"}" style="width: 24px; display: inline-block;"/>
                    ${babies}
                  </div>`;
          }

          pdfContent += `<div style="margin-top: 16px;">
                    <div style="font-size: 12px; font-weight: 400; line-height: 16px; letter-spacing: 0.015em; color: #606669; text-transform: uppercase; margin-bottom: 24px; break-inside: avoid;">${bookingsBlock.dataset.additionalinfo}</div>
                    <div style="display: flex; flex-direction: column; gap: 16px; margin-bottom: 24px;">`;

          if (serviceList != null) {
            serviceList.forEach((service) => {
              pdfContent += `<div style="display: flex; flex-direction: row; gap: 8px; break-inside: avoid;">${service.innerHTML}</div>`;
            });
          }

          pdfContent += "</div>";

          const pdfOptions = {
            filename: item.dataset.locator + ".pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 2,
              useCORS: true,
              logging: true,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            pagebreak: { mode: "css" },
          };

          const funcionality = item.dataset.funcionality;
          const loader = item.querySelector(".js-loader");

          switch (funcionality) {
            case "print":
              html2pdf()
                .set(pdfOptions)
                .from(pdfContent)
                .outputPdf("dataurlnewwindow")
                .then(function () {
                  loader.style.display = "";
                  // pdfWindow.frames.print();

                  // const newWindow = window.open("", "_blank");
                  // if (newWindow) {
                  //   newWindow.document.write(`<iframe width="100%" height="100%" src="${pdfWindow}"></iframe>`);
                  //   newWindow.focus();
                  //   newWindow.print();
                  // }
                });

              break;

            case "download":
              html2pdf()
                .set(pdfOptions)
                .from(pdfContent)
                .save()
                .then(() => {
                  loader.style.display = "";
                });

              break;

            case "share":
              html2pdf()
                .set(pdfOptions)
                .from(pdfContent)
                .outputPdf("datauristring")
                .then(function (pdfString) {
                  const byteCharacters = atob(pdfString.split(",")[1]);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: "application/pdf" });
                  const file = new File([blob], pdfOptions.filename, { type: "application/pdf" });

                  if (navigator.share) {
                    navigator.share({
                      title: "Compartir PDF",
                      text: "Aquí tienes el PDF de mi reserva.",
                      files: [file],
                    });
                  } else {
                    const url = URL.createObjectURL(blob);
                    const tempLink = document.createElement("a");
                    tempLink.href = url;
                    tempLink.download = pdfOptions.filename;
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    URL.revokeObjectURL(url);
                  }

                  loader.style.display = "";
                });

              break;

            default:
              break;
          }
        }
      });
    }
  },
};

bcl.c.mybCurrentBooking.preInit();
